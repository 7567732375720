<template>
  <!-- HEADER -->
  <div class="dl-header">
    <img src="./assets/logo.png" />
  </div>
  <!-- END HEADER -->

  <!-- MAIN SECTION -->
  <div class="main-section">
    <div class="container">
      <div class="columns">
        <div class="column is-6 photo-container">
          <img src="./assets/foto2.png" />
        </div>
        <div class="column copy-container has-text-centered">
          <p class="is-size-2 gold-lines-serif light-brown">¡NOS CASAMOS!</p>
          <p class="is-size-5 light-brown">
            Lo tenemos todo: el traje, el hotel, las flores... Pero nos falta lo
            básico: contar contigo en este día tan importante para nosotros.
          </p>
          <p class="names-heading gold bailenson">Laru & Diego</p>
          <p class="is-size-5 light-brown date">Sabado 09 de Marzo 2024 <br> 7:00pm</p>
          <button @click="scrollToElement()" class="button is-primary confirmation-cta">
            CONFIRMAR ASISTENCIA
          </button>
          <p class="is-size-5 light-brown">Ceremonia Religiosa</p>
          <p class="is-size-5 light-brown religious-ceremony-location">
            Iglesia San Francisco De Asís De La Caleta
          </p>
          <p class="is-size-5 light-brown">Y posteriormente la recepción será en:</p>
          <p class="is-size-5 light-brown reception-location">
            Salón Gran Barú, Hotel Sheraton
          </p>
          <div class="columns brown">
            <div class="column">
              <p class="countdown-numbers bailenson">{{ days }}</p>
              <p class="is-size-5 gold-lines">Dias</p>
            </div>
            <div class="column">
              <p class="countdown-numbers bailenson">{{ hours }}</p>
              <p class="is-size-5 gold-lines">Horas</p>
            </div>
            <div class="column">
              <p class="countdown-numbers bailenson">{{ minutes }}</p>
              <p class="is-size-5 gold-lines">Minutos</p>
            </div>
            <div class="column">
              <p class="countdown-numbers bailenson">{{ seconds }}</p>
              <p class="is-size-5 gold-lines">Segundos</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END MAIN SECTION -->

  <!-- RSVP SECTION -->
  <div class="rsvp-section" id="rsvp-section">
    <div class="container">
      <div class="columns">
        <div class="column" />
        <div class="column is-10">
          <div class="rsvp-form-container">
            <div class="box">
              <input class="input" type="text" placeholder="Nombre completo" style="margin-bottom: 15px" required
                v-model="formData.name" />
              <h6 class="title is-6" style="margin-bottom: 5px">
                Confirmación de asistencia
              </h6>
              <div class="control" style="margin-bottom: 15px">
                <label class="radio">
                  <input type="radio" name="assistance" v-model="formData.assistance" :value="true" />
                  Si asistiré
                </label>
                <br />
                <label class="radio">
                  <input type="radio" name="assistance" v-model="formData.assistance" :value="false" />
                  No podré asistir
                </label>
                <br />
                <label class="radio">
                  Cantidad de puestos a confirmar
                  <select name="cars" id="cars" v-model="formData.seats">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </label>
              </div>
              <label class="checkbox" style="margin-bottom: 20px">
                <input type="checkbox" v-model="formData.reservation" />
                Me interesaria reservar una habitacion en el hotel (opcional)
              </label>
              <br />
              <textarea class="textarea" placeholder="Comentarios adicionales (opcional)" v-model="formData.comments"
                style="margin-bottom: 20px"></textarea>
              <button @click="submitForm()" :class="`button is-primary ${formLoading ? 'is-loading' : ''
                }`" :disabled="formLoading">Enviar</button>
            </div>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </div>
  <!-- END RSVP SECTION -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import Swal from 'sweetalert2';


const formData = ref({
  name: "",
  assistance: null,
  reservation: false,
  comments: "",
  seats: 1

});

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const date = new Date("Mar 9, 2024 18:00:00").getTime();
const formLoading = ref(false)
function scrollToElement() {
  document.getElementById("rsvp-section").scrollIntoView({
    behavior: "smooth",
  });
}
function submitForm() {
  if (!formData.value.name) {
    Swal.fire("Por favor ingresa tu nombre completo");
    return;
  }
  if (formData.value.assistance === null) {
    Swal.fire("Por favor selecciona si asistirás o no");
    return;
  }
  formLoading.value = true
  const data = `name=${formData.value.name}&assistance=${formData.value.assistance}&reservation=${formData.value.reservation}&comments=${formData.value.comments}&seats=${formData.value.seats}`
  fetch('https://script.google.com/macros/s/AKfycbzYngmkAD6VE1eRZLZcvVAadO0W9Ifp64oEq1fcIbwkQNMwjovGcVZKUpE9YDEwWjqc/exec', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: data
  })
    .then(response => response.json())
    .then(data => {
      Swal.fire("Muchas gracias por confirmar tu asistencia!");
      console.log('Success:', data);
      formData.value.name = ''
      formData.value.assistance = null
      formData.value.reservation = false
      formData.value.comments = ''
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      formLoading.value = false
    });
}

onMounted(() => {
  let x = setInterval(() => {
    let now = new Date().getTime();
    let distance = date - now;
    days.value = Math.floor(distance / (1000 * 60 * 60 * 24));
    hours.value = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    minutes.value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    seconds.value = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 0) {
      clearInterval(x);
      days.value = 0;
      hours.value = 0;
      minutes.value = 0;
      seconds.value = 0;
    }
  }, 1000);
});
</script>

<style>
/* IMPORT BULMA */
@import url("bulma/css/bulma.min.css");

/* BULMA COLUMNS RESET asd */
.columns {
  width: 100%;
}

/* FONTS */
@font-face {
  font-family: "Bailenson";
  src: url("./assets/fonts/bailenson.regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoldLinesRegular";
  src: url("./assets/fonts/gold-lines-trial.regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoldLinesSerif";
  src: url("./assets/fonts/gold-lines-trial.serif-regular.woff");
  font-weight: normal;
  font-style: normal;
}

.bailenson {
  font-family: "Bailenson";
}

.gold-lines {
  font-family: "GoldLinesRegular";
}

.gold-lines-serif {
  font-family: "GoldLinesSerif";
}

/* COLORS */
.light-brown {
  color: #af9286;
}

.brown {
  color: #827670;
}

.gold {
  color: #9b8223;
}

/* HEADER  */
.dl-header {
  background-color: #af9286;
  padding: 20px 0;
}

.dl-header img {
  width: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* MAIN SECTION */
.main-section {
  background-image: url("./assets/fondo1.png");
  padding: 125px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.photo-container {
  padding-left: 35px;
  padding-right: 15px;
}

.copy-container {
  padding: 0 25px;
}

.names-heading {
  font-size: 5rem;
}

.date {
  margin-bottom: 20px;
}

.button.is-primary {
  background-color: #827670 !important;
  border-color: transparent;
  color: #fff;
  padding: 25px 35px;
}

.confirmation-cta {
  margin-bottom: 20px;
}

.religious-ceremony-location {
  margin-bottom: 15px;
}

.reception-location {
  margin-bottom: 45px;
}

.countdown-numbers {
  font-size: 5rem;
  line-height: 4rem;
}

/* RSVP SECTION */
.rsvp-section {
  background-image: url("./assets/fondo2.png");
  padding: 125px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.rsvp-form-container {
  padding-left: 25px;
}
</style>
